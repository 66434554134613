import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { string } from 'yup';

import { useOnLoadTracking } from '../../hooks/useTracking';
import { OrderProductType } from '../../model/OrderProduct';
import { formatPrice } from '../../model/Price';
import { formatDatelike } from '../../services/DateTime';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { loadOrderTripPricing, type OrderChangeData, updateOrder } from '../../store/order';
import Image from '../Image';
import { SubmitButton } from '../Input/SubmitButton';
import { useField, useValidatedField } from '../Input/useField';
import { useForm } from '../Input/useForm';
import Loader from '../Loader';
import CancellationPolicy from '../Search/CancellationPolicy';
import Deposit from '../Search/DepositInfo';
import type { SwitchChildrenProps } from '../Switch';
import T, { useTranslation } from '../Translate';

import AdditionalServicesSection from './AdditionalServicesSection';
import DiscountsSection from './DiscountsSection';
import InsuranceSection from './InsuranceSection';
import MessageSection from './MessageSection';
import PaymentMethodSection from './PaymentMethodSection';
import PriceDetail from './PriceDetail';
import ServicesSection from './ServicesSection';

import styles from './order.module.scss';

interface Props {
  onComplete(): void;
}

export default function OrderSummaryPage(props: Props & SwitchChildrenProps<number>) {
  const dispatch: DispatchFunction = useDispatch();
  const { order, lang } = useSelector((state: ReduxState) => ({
    order: state.order,
    lang: state.app.language,
  }));
  const [priceDetailOpened, setPriceDetailOpened] = useState(false);
  useOnLoadTracking(() => Tracking.track('BOOKING_VIEWED'));

  const { t } = useTranslation();
  const [message, setMessage] = useValidatedField(
    'message',
    order.message,
    string()
      .required('global.required')
      .min(30, val => {
        const value = val.value?.trim() ?? '';
        return t('global.textarea.charactersMin', {
          count: val.min - value.length,
        });
      })
      .max(500, val => {
        const value = val.value?.trim() ?? '';
        return t('global.textarea.charactersMax', {
          count: value.length - val.max,
        });
      }),
  );
  const [paymentMethod, setPaymentMethod] = useField('paymentMethod', order.paymentMethod, value =>
    !!value && typeof value === 'object' && 'nonce' in value ? null : 'global.paymentMethod.required',
  );

  const [handleSubmit, form] = useForm(
    [message, paymentMethod],
    async () => {
      dispatch(
        updateOrder({
          message: message.value,
          paymentMethod: paymentMethod.value,
        }),
      );
      Tracking.track('BOOKING_SUBMITTED', order.trip);
      props.onComplete();
    },
    {
      highlightErrors: true,
    },
  );

  const handleChange = async (data: OrderChangeData) => {
    dispatch(loadOrderTripPricing(data));
  };

  return (
    <main className={cn(styles.container, 'main-container container--top container--bottom')}>
      <form className="position-relative" onSubmit={handleSubmit}>
        <div className="container">
          <div className="aside-grid">
            <article className="aside-grid__main">
              <div className="container">
                <div className="row mb-5 justify-content-between">
                  <div className="col-auto">
                    <T as="h1" id="booking.summary.title" />
                    <h2>{order.trip.vehicle.name}</h2>
                    <h3 className="h5">
                      {formatDatelike(order.trip.date_from, 'eeee d.M. H:mm')} -{' '}
                      {formatDatelike(order.trip.date_to, 'eeee d.M. H:mm')}
                    </h3>
                  </div>
                  <div className="col-auto flex-shrink-0">
                    <Image className="rounded-lg" size="sm" src={order.trip.vehicle.main_image} />
                  </div>
                </div>
                <InsuranceSection
                  insurances={order.tripInsuranceChoices}
                  onChange={handleChange}
                  selected={order.selectedMandatoryInsurance}
                />
                <Deposit
                  deposit={order.trip.pricing.deposit}
                  ns="booking.summary"
                  ownInsurance={order.tripInsuranceChoices.some(it => it.type === OrderProductType.OWN_INSURANCE)}
                />
                <ServicesSection
                  onChange={handleChange}
                  selected={order.selectedProducts}
                  services={order.otherTripInsurances}
                />
                <AdditionalServicesSection
                  onChange={handleChange}
                  selected={order.selectedProducts}
                  services={order.otherProducts}
                />
                <MessageSection error={message.error} onChange={setMessage} value={message.value} />
                <DiscountsSection onChange={handleChange} salesCode={order.saleCode} useReward={order.useReward} />
                <CancellationPolicy cancelUntil={order.trip.free_cancellation_time} ns="booking.summary" />
                <PaymentMethodSection
                  clientToken={order.client_token}
                  country={order.trip.vehicle.country.alpha2_code}
                  error={form.submitted && paymentMethod.error}
                  onChange={setPaymentMethod}
                  title="booking.summary.payment.title"
                  value={paymentMethod.value}
                />
                <div className="d-none d-lg-flex justify-content-center">
                  <SubmitButton lg primary submitting={form.submitting} track="BOOKING_SUBMIT_CLICKED">
                    <T id="booking.summary.submit" />
                  </SubmitButton>
                </div>
              </div>
            </article>
            <PriceDetail onClose={() => setPriceDetailOpened(false)} opened={priceDetailOpened} />
          </div>
        </div>
        <div className={styles.bottomBar}>
          <div>
            <div className="d-flex flex-column" onClick={() => setPriceDetailOpened(true)}>
              <div className="d-flex align-items-center">
                {order.updating ? (
                  <Loader />
                ) : (
                  <T
                    className="font-weight-bold mr-2"
                    data={{ priceTotal: formatPrice(order.trip.price, lang) }}
                    id="booking.summary.bottomBar.price"
                  />
                )}
                <i className="icon icon-info" />
              </div>
              {order.updating ? (
                <Loader />
              ) : (
                <T
                  className="text-muted"
                  data={{
                    depositAmount: formatPrice(order.trip.pricing.deposit, lang),
                  }}
                  id="booking.summary.bottomBar.deposit"
                />
              )}
              <T as="span" className="d-block text-underline text-primary" id="booking.summary.bottomBar.priceDetail" />
            </div>
          </div>
          <SubmitButton primary submitting={form.submitting} track="BOOKING_SUBMIT_CLICKED">
            <T id="booking.priceDetail.submit" />
          </SubmitButton>
        </div>
      </form>
    </main>
  );
}
